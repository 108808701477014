<template>
    <v-container class="px-0">
        <v-subheader>Your PayPal subscription</v-subheader>
        <v-data-table class="elevation-1" :headers="headers" :items="paypalList" headers-length="50px" hide-actions>
            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
            <template v-slot:items="{item}">
                <td>{{ item.billing_agreement_id }}</td>
                <td class="text-xs-left">{{ item.package_name }}</td>
                <td class="text-xs-left">{{ item.billing_cycle}}</td>
                <td class="text-xs-left " ><span class="doller-font">${{item.paypal_details.price}}</span></td>
                <td class="text-xs-left " >{{item.paypal_details.status}}</td>
                <td class="text-xs-left">
                    <v-btn small @click="$refs.cancelconfirm.open(item)" :loading="item.deleteLoading" color="primary">Cancel</v-btn>
                </td>
            </template>
            <template v-slot:no-data>
                <div style="min-height: 192px;position: relative;">
                    <Spin v-show="loading"></Spin>
                    <Empty v-show="!loading">
                        <span>It seems that you didn't create any PayPal subscription . <br>Please note that PayPal subscription can only be created when you pay your invoices.</span>
                    </Empty>
                </div>
            </template>
        </v-data-table>
        <Confirm ref="cancelconfirm" @confirm="deleteSubscription">
            <template v-slot:title>
                Cancel PayPal Subscription
            </template>
            <template v-slot:content>
                Are you sure you want to cancel the subscription? If you cancel now, you'll have to set up automatic payments again to pay with PayPal.
            </template>
        </Confirm>
    </v-container>
</template>

<script>
    import Empty from '@/components/Empty'
    import Spin from '@/components/Spin'
    import Confirm from '@/components/Confirm'
    export default {
        name: "Paypal",
        components: {
            Empty,
            Spin,
            Confirm
        },
        data() {
            return {
                deleteLoading: false,
                loading: false,
                headers: [
                    {text: 'ID', sortable: false, value: 'id'},
                    {text: 'Application', sortable: false, value: 'package_name'},
                    {text: 'Billing Cycle', sortable: false, value: 'billing_cycle'},
                    {text: 'Price', sortable: false, value: 'paypal_details.price'},
                    {text: 'Status', sortable: false, value: 'paypal_details.status'}
                ],
                paypalList: []
            }
        },
        methods: {
            initPaypalSuscriptions() {
                this.loading = true
                this.$http({
                    method: 'get',
                    url: `/paypal-subscriptions/?client_id=${this.$store.state.client.clientInfo.id}`,
                }).then(res => {
                    this.loading = false
                    this.paypalList = res.results.map(o => {
                        o.deleteLoading = false
                        return o
                    })
                }).catch(() => {
                    this.loading = false
                    this.$message.error('Oops, it failed to list your PayPal subscription, please contact us at support@cloudclusters.io for this issue.')
                })
            },
            deleteSubscription(item) {
                return this.$http({
                    method: 'delete',
                    url: `/paypal-subscriptions/${item.uuid}/`,
                }).then(() => {
                    this.initPaypalSuscriptions()
                    this.$message.success('Your PayPal subscription was removed successfully.')
                }).catch(() => {
                    this.$message.error('Oops, it failed to remove your PayPal subscription, please contact us at support@cloudclusters.io for this issue.')
                })
            }
        },
        created() {
            this.initPaypalSuscriptions()
        },
    }
</script>

<style lang="scss">

</style>
