var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0" },
    [
      _c("v-subheader", [_vm._v("Your PayPal subscription")]),
      _c(
        "v-data-table",
        {
          staticClass: "elevation-1",
          attrs: {
            headers: _vm.headers,
            items: _vm.paypalList,
            "headers-length": "50px",
            "hide-actions": ""
          },
          scopedSlots: _vm._u([
            {
              key: "items",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("td", [_vm._v(_vm._s(item.billing_agreement_id))]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(item.package_name))
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(item.billing_cycle))
                  ]),
                  _c("td", { staticClass: "text-xs-left " }, [
                    _c("span", { staticClass: "doller-font" }, [
                      _vm._v("$" + _vm._s(item.paypal_details.price))
                    ])
                  ]),
                  _c("td", { staticClass: "text-xs-left " }, [
                    _vm._v(_vm._s(item.paypal_details.status))
                  ]),
                  _c(
                    "td",
                    { staticClass: "text-xs-left" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            loading: item.deleteLoading,
                            color: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.cancelconfirm.open(item)
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "no-data",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "min-height": "192px",
                        position: "relative"
                      }
                    },
                    [
                      _c("Spin", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading"
                          }
                        ]
                      }),
                      _c(
                        "Empty",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.loading,
                              expression: "!loading"
                            }
                          ]
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "It seems that you didn't create any PayPal subscription . "
                            ),
                            _c("br"),
                            _vm._v(
                              "Please note that PayPal subscription can only be created when you pay your invoices."
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-progress-linear", {
            attrs: { color: "blue", indeterminate: "" },
            scopedSlots: _vm._u([
              {
                key: "progress",
                fn: function() {
                  return undefined
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c("Confirm", {
        ref: "cancelconfirm",
        on: { confirm: _vm.deleteSubscription },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _vm._v("\n            Cancel PayPal Subscription\n        ")
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _vm._v(
                  "\n            Are you sure you want to cancel the subscription? If you cancel now, you'll have to set up automatic payments again to pay with PayPal.\n        "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }